import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, DOCUMENT, Meta } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { DataService } from '../utilities/services/data.service'
import { HtmlService } from '../utilities/services/html.service';
import { MemberService } from '../utilities/services/member.service';
import { LoaderService } from '../utilities/services/loader.service';
import { WINDOW } from "../utilities/services/window.service";
import { IExternalSurvey } from 'app/utilities/models/external-survey.vm';

interface FileLink {
  label: string;
  link: string;
  icon?: string;
}

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss']
})
export class ProjectOverviewComponent implements OnInit {

  projectOverview; date;
  yearError: boolean = false;

  currentYearDate: number; currentOrganisation: number; currentOrganisationName: string;
  userRole: number;
  region: number;

  yearSpecific; currentYear: number; hiddenYear: boolean = false;
  organisationQuestions: string; projectQuestions: string; serviceQuestions: string; qualificationQuestions: string;

  organisationSubmission = []; submissionSpecific = []; currentSubmission: number; caseNoteReview: string = 'N'; peerGroupId: number;
  currentSubmissionName; currentServiceName;
  submissionLocked: boolean; registrationLocked; allowLock: boolean = false;

  serviceSpecific; currentService: number;
  status: boolean = true;
  projectOpen: boolean; projectValidation: boolean; projectReadOnly: boolean;

  projectId: number;
  projectOverviewOrganisations;
  projectOverviewSubmissions;
  projectManager; projectLead; projectCoordinator; submissionLeadName; submissionLeadEmail;
  currentPeerGroup: number; staffSurveyLink: string; surveyCount: number;  qualitySurveyLink: string; qualitySurveyCount: number;
  mhSubmissions: boolean; acSubmissions: boolean;

  introFiles: Array<FileLink> = [];

  // Pagination
  caseNoteCodes; filteredCaseNoteCodes; caseNoteCodeLength; exportCodes;
  caseCodeSource;
  maxSize; startItem; endItem; currentPage: number;

  // Forms
  caseCodeNotesForm: FormGroup;
  showCodeNotesForm: boolean = false;
  currentCaseCode: string;
  crcCheck: string;
  cnrFilter: string = null;

  // CSV Export Options
  csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [ 'Case Codes' ],
    useBom: false,
    removeNewLines: true,
    keys: [ 'caseCode' ]
  };

  // Guidance
  guidanceIntro; guidanceOrganisation; guidanceProject; guidanceService;
  guidanceOrganisationTitle; guidanceProjectTitle; guidanceServiceTitle;
  guidanceTimeSeries; guidanceMonthly;

  // Qualification
  qualification: boolean = false;
  qualificationAnswered: boolean = false;

  // Intermediate Care
  activeAdmin: boolean = false;

  // ATD
  atdProject: boolean = false;

  public navIsFixed: boolean = false;
  public width: number;
  externalSurvey: IExternalSurvey;
  filterValue: number = null;
  casecodesFirstHalfFilter: number = 1;
  casecodesSecondHalfFilter: number = 2;
  firstHalfCaseCodes: boolean = false;
  public selectedHalf: string;
  metaTitle: string = 'Project Overview | NHS Benchmarking Network';

  internalSurvey: { projectId: number; name: string; shortName: string; maxNumberCodes: number; };

  // TODO: remove extendDataCollectionForNacelEandW2024 from the component once NACEL E&W 2024 Data collection is closed (10/01/25)
  public extendDataCollectionForNacelEandW2024: boolean 
   

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private htmlService: HtmlService,
    private memberService: MemberService,
    private titleService: Title,
    private meta: Meta,
    public loaderService: LoaderService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window
  ) {
    this.date = new Date().toISOString();
  }

  ngOnInit() {
    this.titleService.setTitle(this.metaTitle);
    this.meta.updateTag({property: 'og:title', content: this.metaTitle});
    this.meta.updateTag({name: 'twitter:title', content: this.metaTitle});
    this.projectId = +this.route.snapshot.paramMap.get('projectId');
    
    // Set pagination if in param
    if (this.route.snapshot.queryParams['page']) {
      this.currentPage = +this.route.snapshot.queryParams['page'];
    } else {
      this.currentPage = 1;
    }

    // Retain current year until 1st April (financial year)
    let calculatedMonth = new Date().getMonth();
    if (calculatedMonth < 3) {
      this.currentYearDate = new Date().getFullYear() - 1;
    } else {
      this.currentYearDate = new Date().getFullYear();
    }

    // Fix for VW
    if(this.projectId == 68) {
      this.currentYearDate = 2023;
    }
    this.getProjectOverview(this.projectId);

    // 1st of July update casecode table
    if(calculatedMonth > 5) {
      this.filterValue = this.casecodesSecondHalfFilter;
    } else {
      this.firstHalfCaseCodes = true;
      this.filterValue = this.casecodesFirstHalfFilter;
    }

    this.setFileList(this.projectId)
  }

  setFileList(projectId: number) {
    switch (projectId) {
      case 40: // Intermediate Care
        this.introFiles = [
          {
            label: 'Core Data Specification',
            link: 'https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/NHSBN_Intermediate_Care_Data_Specification_2024_FINAL_AMENDED.xlsx',
            icon: 'fas fa-file-excel'
          },
          {
            label: 'Clinical Case Review Specification',
            link: 'https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/NHSBN_Intermediate_Care_Clinical_Case_Review_Specification_2024_FINAL.xlsx',
            icon: 'fas fa-file-excel'
          }
        ]
    }
  }

  getProjectOverview(projectId: number) {
    this.memberService.ProjectOverviewOrganisations(projectId).subscribe(
      a => {
        this.projectOverviewOrganisations = a.data;
        // Project-specific guidance
        let guidance = this.projectOverviewOrganisations.projectGuidance
        if (guidance) {
          for (let index = 7; index <= 18; index++) {
            let selectedGuidance = guidance.filter(p => p.guidanceTypeId == index)
            if (selectedGuidance[0]) {
              switch (index) {
                case 7: {
                  this.guidanceIntro = selectedGuidance[0].guidanceText;
                  break;
                }
                case 9: {
                  this.guidanceOrganisation = selectedGuidance[0].guidanceText;
                  break;
                }
                case 10: {
                  this.guidanceProject = selectedGuidance[0].guidanceText;
                  break;
                }
                case 11: {
                  this.guidanceService = selectedGuidance[0].guidanceText;
                  break;
                }
                case 12: {
                  this.guidanceOrganisationTitle = selectedGuidance[0].guidanceText;
                  break;
                }
                case 13: {
                  this.guidanceProjectTitle = selectedGuidance[0].guidanceText;
                  break;
                }
                case 14: {
                  this.guidanceServiceTitle = selectedGuidance[0].guidanceText;
                  break;
                }
                case 17: {
                  this.guidanceTimeSeries = selectedGuidance[0].guidanceText;
                  this.atdProject = true;
                  break;
                }
                case 18: {
                  this.guidanceMonthly = selectedGuidance[0].guidanceText;
                  break;
                }
              }
            }
          }
        }
        this.organisation();
      }
    )
  }

  getSubmissions(projectId, organisationId) {
    this.memberService.ProjectOverviewSubmissions(projectId, organisationId).subscribe(
      b => {
        this.projectOverviewSubmissions = b.data;

        let principleContact = this.projectOverviewSubmissions.projectRoleList.filter(p => p.principleContact == 'Y')[0];

        if (principleContact) {
          this.projectManager = principleContact;
        } else {
          this.projectManager = this.projectOverviewSubmissions.projectRoleList.filter(p => p.roleName == 'Project Coordinator')[0];
        }

        this.projectLead = this.projectOverviewSubmissions.projectLeadList[0];

        this.projectCoordinator= this.projectOverviewSubmissions.projectRoleList.filter(r=>r.roleName==="Project Coordinator")[0];

        this.userRole = this.projectOverviewSubmissions.submissionData.filter(o => o.organisationId == organisationId)[0].roleType;

        let openDate, closeDate, readOnlyDate;

        if (this.projectOverviewSubmissions.currentYearOpenDate) {
          openDate = this.projectOverviewSubmissions.currentYearOpenDate;
        } else {
          openDate = this.projectOverviewSubmissions.latestYearOpenDate;
        }

        if (this.projectOverviewSubmissions.currentYearCloseDate) {
          closeDate = this.projectOverviewSubmissions.currentYearCloseDate;
        } else {
          closeDate = this.projectOverviewSubmissions.latestYearCloseDate;
        }

        if (this.projectOverviewSubmissions.currentYearReadOnlyDate) {
          readOnlyDate = this.projectOverviewSubmissions.currentYearReadOnlyDate;
        } else {
          readOnlyDate = this.projectOverviewSubmissions.latestYearReadOnlyDate;
        }

        if (this.date > openDate && this.date < closeDate) {
          this.projectOpen = true;
          this.projectValidation = false;
          this.projectReadOnly = false;
        }
        else if (this.date > closeDate && this.date < readOnlyDate) {
          this.projectOpen = false;
          this.projectValidation = true;
          this.projectReadOnly = false;
        }
        else {
          this.projectOpen = false;
          this.projectValidation = false;
          this.projectReadOnly = true;
        }
        this.year(this.currentOrganisation);
      }
    );
  }

  filterCaseNoteCodes(casecodeHalf: number) {
    let calculatedMonth = new Date().getMonth();
    this.filterValue = casecodeHalf;
    this.caseNoteCodes = this.caseCodeSource;
    if(calculatedMonth > 5 && casecodeHalf == this.casecodesFirstHalfFilter) {
      this.caseNoteCodes = this.caseNoteCodes.filter(data => data.year_half == casecodeHalf)
    } else {
      this.caseNoteCodes = this.caseNoteCodes.filter(data => data.year_half == casecodeHalf || data.year_half == null)
    }

    this.currentPage = 1;
    this.caseNoteCodeLength = this.caseNoteCodes.length;
    this.filteredCaseNoteCodes = this.caseNoteCodes.slice(0, 10);
  }

  getCaseNotes(submissionId: number) {
    this.filteredCaseNoteCodes = null;
    this.memberService.CNRCodes(submissionId).subscribe(
      d => {
        this.caseNoteCodes = d.data.caseNoteCodes;
        this.caseCodeSource = this.caseNoteCodes;
        this.crcCheck = d.data.crcCheck;
        this.caseNoteCodeLength = this.caseNoteCodes.length;
        this.filteredCaseNoteCodes = this.caseNoteCodes.slice(0, 10);
        this.maxSize = 8
        let setPage = {
          page: this.currentPage,
          itemsPerPage: 10
        };
        this.pageChanged(setPage);
        this.currentPage = this.currentPage;
        // Set export codes
        let exportCodes = []
        this.caseNoteCodes.forEach(e => {
          exportCodes.push({
            caseCode: e.caseCode.substring(5).slice(1,-5)
          })
        });
        this.exportCodes = exportCodes;

        // Only filter casecodes for NACEL
        if(this.projectId == 34) {
          this.filterCaseNoteCodes(this.filterValue);
        }
      }
    );
  }

  year(organisationId: number) {
    //Get the years for the organisation selected
    let isAdmin = JSON.parse(sessionStorage.getItem('user')),
        availableYears = this.projectOverviewSubmissions.submissionData.filter(o => o.organisationId == organisationId)[0].years;

    if (isAdmin && isAdmin.isAdmin === 'Y') {
      this.yearSpecific = availableYears;
      this.activeAdmin = true;
    } else {
      this.yearSpecific = availableYears.filter(y => y.dataCollectionVisible !== 'N');
    }

    //Set the year in the drop down list to the most recent year or error
    if (this.yearSpecific.length) {

      this.currentYear = this.yearSpecific[0].year;

      if (this.yearSpecific[0].dataCollectionVisible === 'N') {
        this.hiddenYear = true;
      }

      //Set variables that determine visibility of options in DOM
      this.organisationQuestions = this.yearSpecific[0].organisationQuestions;
      this.projectQuestions = this.yearSpecific[0].projectQuestions;
      this.serviceQuestions = this.yearSpecific[0].serviceQuestions;

      //Process submission
      this.submission(this.currentYear);

    } else {
      this.yearError = true;
    }

  }

  organisation() {
    //Check to see if there is a previously selectedOrganisation
    let selectedOrganisation = this.dataService.getSelectedOrganisation();
    let organisationExists = this.projectOverviewOrganisations.organisationList.map(o => o.organisationId).includes(selectedOrganisation);

    //Set the organisation dropdown to either the first value in the list or selectedOrganisation and set user role
    if (organisationExists) {
      this.currentOrganisation = selectedOrganisation;
    }
    else {
      this.currentOrganisation = this.projectOverviewOrganisations.organisationList[0].organisationId;
    }

    // Set the organisation's region
    this.region = this.projectOverviewOrganisations.organisationList.filter(o => o.organisationId === this.currentOrganisation)[0].regionId;

    // Set organisation name
    this.currentOrganisationName = this.projectOverviewOrganisations.organisationList.filter(o => o.organisationId == this.currentOrganisation)[0].organisationName;

    this.getSubmissions(this.projectId, this.currentOrganisation);
  }

  submission(year: number) {
    this.organisationSubmission = []; this.submissionSpecific = []; this.serviceSpecific = [];

    this.yearSpecific.forEach(element => {
      if (element.year == year) {
        this.organisationSubmission = element.organisationSubmissions[0];

        element.submissions.forEach(submission => {
          this.submissionSpecific.push(submission);
        });
      }
    });
    // Check for Mental Health submissions in NACEL
    if (this.projectId === 34 && this.submissionSpecific.map(s => s.peerGroupId).includes(3)) {
      this.mhSubmissions = true;
    } else {
      this.mhSubmissions = false;
    }

    // Check for Acute/Community submissions in NACEL
    if (this.projectId === 34 && (this.submissionSpecific.map(s => s.peerGroupId).includes(1) || this.submissionSpecific.map(s => s.peerGroupId).includes(2))) {
      this.acSubmissions = true;
    } else {
      this.acSubmissions = false;
    }

    let availableSubmissions = this.submissionSpecific.map(s => s.submissionId),
        savedSubmission = +this.route.snapshot.queryParams['submission'];

    // Load submission automatically if only one
    if (savedSubmission && availableSubmissions.includes(savedSubmission)) {
      this.onSubmissionChange(savedSubmission);
    } else if (this.submissionSpecific.length === 1) {
      this.currentSubmission = this.submissionSpecific[0].submissionId;
      this.onSubmissionChange(this.currentSubmission);
    } else {
      this.currentSubmission = 0;
    }

    // Set userRoleType for year
    let userRoleType = this.submissionSpecific[0].userRoleType;
    if (userRoleType && [1, 2, 3, 8].includes(userRoleType)) {
      this.allowLock = true;
    }
  }

  service(submissionId: number) {
    // Populate Service
    this.submissionSpecific.forEach(element => {
      if (element.submissionId == submissionId) {
        this.serviceSpecific = element.services;
      }
    });

    // Set the service in the drop down list to the most recent service
    let serviceParam = this.route.snapshot.queryParams['service'];
    if (serviceParam && serviceParam != 0) {
      this.currentService = +serviceParam;
      this.currentServiceName = this.serviceSpecific.filter(s => s.serviceItemId === this.currentService)[0].serviceItemName;
    } else {
      this.currentService = this.serviceSpecific[0].serviceItemId;
      this.currentServiceName = this.serviceSpecific[0].serviceItemName;
    }
  }

  onYearChange(message: number) {
    // Set the year - might not be necessary
    this.currentYear = message;
    // TODO: remove extendDataCollectionForNacelEandW2024 from the component once NACEL E&W 2024 Data collection is closed (10/01/25)
    this.extendDataCollectionForNacelEandW2024 = (this.currentYear == 2024 && this.projectId == 34) ? true : false
    if (this.extendDataCollectionForNacelEandW2024) this.projectReadOnly = false

    this.yearSpecific.forEach(element => {
      if (element.year == message) {
        // Set variables that determine visibility of options in DOM
        this.organisationQuestions = element.organisationQuestions;
        this.projectQuestions = element.projectQuestions;
        this.serviceQuestions = element.serviceQuestions;
      }
    });

    if (this.yearSpecific.filter(y => y.year === message)[0].dataCollectionVisible === 'N') {
      this.hiddenYear = true;
    } else {
      this.hiddenYear = false;
    }

    // Process submission
    this.submission(message);
  }

  onOrgChange(message: number) {
    // If organisation is changed, set new organisation as selectedOrganisation
    this.dataService.setSelectedOrganisation(message);

    // Set currentOrganisation details
    this.currentOrganisation = message;
    let organisation = this.projectOverviewOrganisations.organisationList.filter(o => o.organisationId == this.currentOrganisation)[0];
    this.currentOrganisationName = organisation.organisationName;
    this.region = organisation.regionId;

    this.getSubmissions(this.projectId, message);
  }

  onSubmissionChange(message: number) {
    // Reset params
    this.router.navigate([], { queryParams: { submission: null, service: null }, queryParamsHandling: 'merge' });

    //Set the submission - might not be necessary
    this.currentSubmission = message;
    this.currentSubmissionName = this.submissionSpecific.filter(s => s.submissionId == message)[0].submissionName;

    // Set submissionLead, if present
    let submissionSpecific = this.submissionSpecific.find(s => s.submissionId == message);
    if (submissionSpecific & submissionSpecific.submissionLeadList[0]) {
      this.submissionLeadName = submissionSpecific.submissionLeadList[0].name;
      this.submissionLeadEmail = submissionSpecific.submissionLeadList[0].email;
    }

    this.submissionSpecific.forEach(element => {
      if (element.submissionId == message) {
        // Set variables that determine visibility of options in DOM
        this.submissionLocked = element.submissionLocked;
        if (element.registrationLocked === 'Y') {
          this.registrationLocked = true;
        } else {
          this.registrationLocked = false;
        }

        // Update case note review
        this.caseNoteReview = element.caseNoteReview;

        this.peerGroupId = element.peerGroupId;

        // Check if there are services
        if (element.services.length > 0) {
          // Process Service
          this.service(message);
          return;
        }
      }
    });

    // Return Case Notes and Staff Survey links (NACEL Mental Health Spotlight Audit)
    if (this.projectId === 88 ) {
      this.getCaseNotes(message)
      this.currentPage = 1;
      this.staffSurveyLink = 'https://surveys.nhsbenchmarking.nhs.uk/nsmh/' + this.currentSubmission + '-' + this.currentOrganisation;
      this.memberService.caseCodeCount(this.currentSubmission, 'NSMH').subscribe(
        r => {
          this.surveyCount = r.data.count;
        },
        e => {
          console.log(e)
        }
      );
    }

    // Return Case Notes and Staff Survey links (NACEL)
    if (this.projectId === 34 ) {
      this.getCaseNotes(message)
      this.currentPage = 1;
      this.staffSurveyLink = 'https://surveys.nhsbenchmarking.nhs.uk/nsac/' + this.currentSubmission + '-' + this.currentOrganisation;
      this.memberService.caseCodeCount(this.currentSubmission, 'NSAC').subscribe(
        r => {
          this.surveyCount = r.data.count;
        },
        e => {
          console.log(e)
        }
      );
    }

    // Return Quality Survey links (NACEL)
    if (this.projectId === 34) {
      this.qualitySurveyLink = 'https://surveys.nhsbenchmarking.nhs.uk/qsac/' + this.currentSubmission + '-' + this.currentOrganisation;
      this.memberService.caseCodeCount(this.currentSubmission, 'QSAC').subscribe(
        r => {
          this.qualitySurveyCount = r.data.count;
        },
        e => {
          console.log(e)
        }
      );
    }

        // Return Case Notes and Staff Survey links (NACEL NI)
    if (this.projectId === 86) {
      this.getCaseNotes(message)
      this.currentPage = 1;
      this.staffSurveyLink = 'https://surveys.nhsbenchmarking.nhs.uk/nsni/' + this.currentSubmission + '-' + this.currentOrganisation;
      this.memberService.caseCodeCount(this.currentSubmission, 'NSNI').subscribe(
        r => {
          this.surveyCount = r.data.count;
        },
        e => {
          console.log(e)
        }
      );
    }

        // Return Quality Survey links (NACEL NI)
    if (this.projectId === 86) {
      this.qualitySurveyLink = 'https://surveys.nhsbenchmarking.nhs.uk/qsni/' + this.currentSubmission + '-' + this.currentOrganisation;
      this.memberService.caseCodeCount(this.currentSubmission, 'QSNI').subscribe(
        r => {
          this.qualitySurveyCount = r.data.count;
        },
        e => {
          console.log(e)
        }
      );
    }

    // Define availble external surveys
    // These will link to the external survey site via a link/code combination and QR code
    let externalSurveys = [
      {
        projectId: 40,
        surveys: [ { name: 'Patient Reported Experience Measure (PREM)', surveyRoute: 'https://surveys.nhsbenchmarking.nhs.uk/pr/', questionGroupLevel: 'PR', userType: "service user", link: [{ id: 'Bed Based Intermediate Care', text: 'https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/Bed%20based%20PREM%20form.pdf'}, {id: 'Intermediate Care Provided at Home', text: 'https://s3.eu-west-2.amazonaws.com/nhsbn-static/Intermediate%20Care%20(Providers)/2024/UCR_Home_reablement_IMDT%20PREM%20form.pdf'}, {id: 'Urgent Community Response'}] }]
      },
      {
        projectId: 50,
        surveys: [ { name: 'staff survey', surveyRoute: 'https://surveys.nhsbenchmarking.nhs.uk/lds/', questionGroupLevel: 'LDS', userType: "staff member" }]
      },
      {
        projectId: 50,
        surveys: [ { name: 'carer survey', surveyRoute: 'https://surveys.nhsbenchmarking.nhs.uk/lds/', questionGroupLevel: 'LDS', userType: "carer"  }]
      },
      {
        projectId: 68,
        surveys: [
          { name: 'Staff Survey', surveyRoute: 'https://surveys.nhsbenchmarking.nhs.uk/vwss/', questionGroupLevel: 'VWSS', userType: "staff member"  },
          { name: 'Friends and Family', surveyRoute: 'https://surveys.nhsbenchmarking.nhs.uk/vwff/', questionGroupLevel: 'VWFF', userType: "carer"  },
          { name: 'Patient Reported Experience Measure (PREM)', surveyRoute: 'https://surveys.nhsbenchmarking.nhs.uk/vwpr/', questionGroupLevel: 'VWPR', userType: "service user"  }
        ]
      }
    ]

    // Load codes for external surveys
    if (externalSurveys.map(s => s.projectId).includes(this.projectId)) {
      // Set survey
      this.externalSurvey = externalSurveys.find(s => s.projectId == this.projectId);

      // Get case codes and set first page
      this.getCaseNotes(message)
      this.currentPage = 1;

      this.externalSurvey.surveys.forEach(survey => {
        // Set survey link
        survey.staffSurveyLink = survey.surveyRoute + this.currentSubmission + '-' + this.currentOrganisation;
        this.memberService.caseCodeCount(this.currentSubmission, survey.questionGroupLevel).subscribe(
          r => {
            survey.surveyCount = r.data.count;
          },
          e => {
            console.log(e);
          }
        );
      })
    }


    // These will make the surveys available within the Members' Area
    // Users will add codes and respond directly
    let internalSurveys = [
      {
        projectId: 35,
        name: 'Clinical Case Review',
        shortName: 'CCR',
        introductoryText: '<p>Organisations are requested to select one care of older people ward and then fill out the CCR for 50 service users who are consecutively discharged from the selected ward in the period from the <strong>6th May 2024 to the 1st July 2024</strong>. If you have multiple care of older people wards in your organisation, you are welcome to complete multiple CCRs. A separate form will need to be completed for each ward if you choose to do this.</p><p>Additional information can be found in the Managing Frailty in the Acute Setting guidance, and you can <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Managing%20Frailty%20in%20the%20Acute%20Setting/2024/NHSBN%20Managing%20Frailty%20Clinical%20Case%20Review%20Specification%202024%20FINAL.xlsx" target="_blank">download the CCR specification here</a>.</p>',
        maxNumberCodes: 50
      },
      {
        projectId: 68,
        name: 'Clinical Case Review',
        shortName: 'CCR',
        introductoryText: '<p>A Clinical Case Review (CCR) should be created and completed for every service user referred into this virtual ward (hospital at home) service during the data collection period. Information about each service user is to be collected on admission to the service and on discharge. More information can be found in the <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Virtual%20Wards%20(also%20known%20as%20Hospital%20at%20Home)/2023/Virtual%20Wards_guidance23_final.pdf" target="_blank">Virtual Ward (also known as Hospital at Home) guidance booklet</a>.</p>',
        maxNumberCodes: 500
      }
    ]

    // Load codes for internal surveys
    if (internalSurveys.map(s => s.projectId).includes(this.projectId)) {
      // Set survey
      this.internalSurvey = internalSurveys.find(s => s.projectId == this.projectId);

      // Get case codes and set first page
      this.getCaseNotes(message)
      this.currentPage = 1;
    }

    // NHSE LDIS
    if (this.projectId === 36) {
      this.currentPeerGroup = this.submissionSpecific.filter(s => s.submissionId === this.currentSubmission)[0].peerGroupId;
      let type;
      if (this.currentPeerGroup === 5) {
        type = 'sp';
      } else (
        type = 'un'
      )
      this.staffSurveyLink = 'https://surveys.nhsbenchmarking.nhs.uk/y/' + this.currentSubmission + '-' + this.currentOrganisation;
      this.memberService.caseCodeCount(this.currentSubmission, 'Y').subscribe(
        r => {
          // TODO: Remove 'if' once fully tested
          if (r.data) {
            this.surveyCount = r.data.count;
          } else {
            this.surveyCount = r.count;
          }
        },
        e => {
          console.log(e)
        }
      )
    }

    // Qualification Questions
    let availableGroups = [];
    if (this.projectOverviewSubmissions.availableGroupLevels[this.currentYear]) {
      availableGroups = this.projectOverviewSubmissions.availableGroupLevels[this.currentYear];
    }
    if (availableGroups.includes('H')) {
      this.qualification = true;
      this.qualificationAnswered = false; // Fail safe
      this.memberService.QuestionGroups(this.projectId, this.currentYear, 'H', this.currentSubmission).subscribe(
        r => {
          // Get first/only groupId in 'H'
          let groupId = r.data[0].groupId;
          this.memberService.Questions(this.projectId, this.currentSubmission, groupId).subscribe(
            r => {
              // Get number of questions in group
              let noQuestions = r.data.questions.filter(q => q.questionType != 'NR').length;

              // Check all questions in group are answered, then allow
              if (r.data.questions.filter(q => q.questionType != 'NR').filter(q => q.responses[0].value).length === noQuestions) {
                this.qualificationAnswered = true;
              }
            }
          )
        }
      )
    } else {
      this.qualification = false;
    }
  }

  onServiceChange(message: number) {
    this.currentService = message;
    this.currentServiceName = this.serviceSpecific.filter(s => s.serviceItemId === message)[0].serviceItemName;
  }

  addCnrCodes(submissionId: number, newCodeCount: number) {
    let cnrCodes = { newCodeCount: newCodeCount }
    this.memberService.addCnrCodes(submissionId, cnrCodes).subscribe(
      r => {
        this.getCaseNotes(this.currentSubmission);
        this.toastr.success('New case codes have been added to your submission.')
      },
      e => {
        this.toastr.error('There has been an error whilst trying to add the case codes. Please try again.');
      }
    );
  }

  editCnrNote(cnrNote) {
    this.memberService.editCnrNotes(cnrNote).subscribe(
      r => {
        this.toastr.success("Reviewer's intials edited successfully.")
        this.showCodeNotesForm = false;
        this.currentCaseCode = null;
        this.getCaseNotes(this.currentSubmission);
      },
      e => {
        this.toastr.error("There has been an error whilst trying to edit the reviewer's initials.");
      }
    );
  }

  buildCaseCodeNotesForm(caseCode) {
    let currentCaseCode = this.caseNoteCodes.filter(c => c.caseCode == caseCode)[0];
    this.caseCodeNotesForm = this.fb.group({
      caseCodeNotes: ['', [Validators.required, Validators.maxLength(200)]]
    });
    // If caseCode exists, set value
    if (currentCaseCode.caseCodeNotes) {
      this.caseCodeNotesForm.setValue({
        caseCodeNotes: currentCaseCode.caseCodeNotes
      });
    }
    this.currentCaseCode = caseCode;
    this.showCodeNotesForm = true;
  }

  caseCodeNotesFormSubmit() {
    let cnrNote = {
      caseCode: this.currentCaseCode,
      caseCodeNotes: this.caseCodeNotesForm.value.caseCodeNotes
    };

    this.editCnrNote(cnrNote);
  }

  pageChanged(event) {
    this.startItem = (event.page - 1) * event.itemsPerPage;
    this.endItem = event.page * event.itemsPerPage;
    this.filteredCaseNoteCodes = this.caseNoteCodes.slice(this.startItem, this.endItem);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: event.page }, queryParamsHandling: 'merge'
    });
  }

  dataCollection(type: string, submissionId?: number) {
    if (type == 'p' || type == 'o' || type === 'om' || type === 'omh' || type === 'oac') {
      this.router.navigate(['/data-collection', type, submissionId || this.currentSubmission, this.currentYear]);
    }
    else if (type == 's') {
      if (this.currentService) {
        this.router.navigate(['/data-collection', type, this.currentSubmission, this.currentYear, this.currentService]);
      }
      else {
        this.router.navigate(['/data-collection', type, this.currentSubmission, this.currentYear]);
      }
    }
    else if (type == 'c' || type == 'd' || type == 'u' || type == 'm' || type == 'a' || type == 'l' || type == 'h' || type == 'uac' || type == 'umh') {
      this.router.navigate(['/data-collection', type, this.currentSubmission, this.currentYear]);
    }
    else {
      console.log("Type not supported: " + type);
    }
  }

  openCaseCode(caseCode: string, serviceItemId?: number) {
    let questionGroup;
    if (this.currentYear >= 2020) {
      questionGroup = this.peerGroupId == 3 ? 'cmh' : 'cac';
    } else {
      questionGroup = 'cac';
    }
    let year_half = this.caseCodeSource.filter(item => item.caseCode == caseCode)[0].year_half;
    if(this.projectId == 34) {
      this.router.navigate(['/data-collection', questionGroup, this.currentSubmission, this.currentYear, serviceItemId ? serviceItemId : 0], { queryParams: { case: caseCode, page: this.currentPage, year_half: year_half } });
    } else {
      this.router.navigate(['/data-collection', questionGroup, this.currentSubmission, this.currentYear, serviceItemId ? serviceItemId : 0], { queryParams: { case: caseCode, page: this.currentPage } });
    }
  }

  removeCaseCode(submission: number, caseCode: string) {
    if (confirm("Are you sure you want to remove this case code?")) {
      let cnrNote = { caseCode: caseCode };
      this.memberService.removeCnrCode(submission, cnrNote).subscribe(
        r => {
          this.getCaseNotes(this.currentSubmission);
          this.toastr.success('The case note has been removed from your submission.')
        },
        e => {
          this.toastr.error('There has been an error whilst trying to remove the case codes. Please try again.');
        }
      );
    }
  }

  purgeCaseCode(caseCode) {

    var purgeCheck = prompt("Are you sure you want to delete this case code and all data associated with it? This action cannot be undone. If you wish to continue, type DELETE in the box below and click 'OK', otherwise click 'Cancel'.");

    if (purgeCheck === 'DELETE') {

      this.memberService.purgeCaseCode({ caseCode: caseCode}).subscribe(
        r => {
          this.getCaseNotes(this.currentSubmission);
          this.toastr.success('The case note, and all associated data, has been deleted from your submission.')
        },
        e => {
          this.toastr.error('There has been an error whilst trying to deleted the case codes. Please try again.');
        }
      );
    } else {
      this.toastr.info('The case code has not been deleted.');
    }

  }

  completeCaseCode(submission: number, caseCode: string, message: string) {
    if (confirm("Are you sure you want to change the status of this case code?")) {
      let cnrNote = {
        caseCode: caseCode,
        dataSubmitted: message
      };
      this.memberService.completeCnrCode(submission, cnrNote).subscribe(
        r => {
          this.getCaseNotes(this.currentSubmission);
          this.toastr.success('The status of this case code has been successfully changed.')
        },
        e => {
          this.toastr.error('There has been an error whilst trying to change the status of this case code. Please try again.');
        }
      );
    }
  }

  setProjectState() {
    if (this.submissionLocked) {
      this.setSubmissionState('unlock', this.currentSubmission, this.currentOrganisation);
    }
    else {
      this.setSubmissionState('lock', this.currentSubmission, this.currentOrganisation);
    }

    this.submissionLocked = !this.submissionLocked;

    this.submissionSpecific.forEach(element => {
      if (element.submissionId === this.currentSubmission) {
        element.submissionLocked = this.submissionLocked;
      }
    });
  }

  setSubmissionState(state: string, submissionId: number, organisationId: number) {
    this.memberService.SubmissionState(state, submissionId, organisationId).subscribe(
      r => {
        if (state === 'lock') {
          this.toastr.success('This submission has been marked as complete.')
        } else if (state === 'unlock')
          this.toastr.success('This submission has been marked as editable.')
       },
      e => {
        this.toastr.error('Could not change the status of this submission. Please refresh the page and try again.')
       }
    );
  }

  navHome() {
    this.router.navigate(['/home']);
  }

  navRegistration(projectId, organisationId) {
    this.router.navigate(['/project-registration/' + projectId + '/' + organisationId]);
  }

  getSelectedDocument(documentId: number) {
    this.memberService.AWSSelectedDocument(documentId, this.currentOrganisation).subscribe(
      a => { window.open(a.data.documentUrl) }
    );
  }

  getExcelDocument(productId: number, regionId?: number, peerGroupId?: number) {

    // NACEL
    if (productId == 5) {
      if ((regionId !== 5) && (peerGroupId == 1 || peerGroupId == 2)) {
        // England - Acute and Community
        window.open('https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2022/nacel_2022_letter_gen_england_jul22.xlsm');
      } else if (regionId == 5 && (peerGroupId == 1 || peerGroupId == 2)) {
        // Wales - Acute and Community
        window.open('https://s3.eu-west-2.amazonaws.com/nhsbn-static/NACEL/2022/NACEL%202022%20Wales%20letter%20gen%20FINAL.xlsm');
      } else {
        console.log('There is no document matching the current region and peer group.')
      }
    }

    // NHSI LD
    if (productId == 6) {
      window.open('../../assets/excel/NHSI-Patient-Survey-Monitoring-Sheet.xlsm');
    }

  }

  copyToClipboard(questionGroupLevel, currentSubmission, currentOrganisation) {
    window.navigator['clipboard'].writeText(`https://surveys.nhsbenchmarking.nhs.uk/${questionGroupLevel}/${currentSubmission}-${currentOrganisation }`);
  }

  public downloadQRCode(index) {
    const fileNameToDownload = 'image_qrcode';
    const base64Img = document.getElementsByClassName('qr-code')[index].children[1]['currentSrc'];
    fetch(base64Img)
      .then(res => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileNameToDownload;
        link.click();
      })
  }

  public onCnrFilterChange(filter: string) {
    this.cnrFilter = filter;
    if (filter == null) {
      this.filteredCaseNoteCodes = this.caseNoteCodes.slice(0, 10);
    } else {
      this.filteredCaseNoteCodes = this.caseNoteCodes.filter(cnr => cnr.completionStatus == filter);
    }
  }

}
