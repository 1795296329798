<div class="container-fluid" id="containerHome">
  <!-- SIDEBAR -->
  <div class="col-sm-3" id="sidebar">
    <div [class.fixed-sidebar]="navIsFixed" [style.width.px]="width">
      <h2>Welcome, {{ user.fullName }}</h2>

      <p>
        Welcome to our Members' Area. If you need any support, please contact the
        <a [routerLink]="['/contact']" routerLinkActive="active"> Support Team</a>
        on 0161 521 0818.
      </p>

      <hr />

      <div class="form-inline">
        <div class="form-group">
          <label for="organisations">Select your organisation:</label>

          <select
            id="organisations"
            class="form-control select-width"
            [ngModel]="currentOrganisation"
            (ngModelChange)="onOrgChange($event)">
            <option
              *ngFor="let o of organisation"
              [ngValue]="o.organisationId"
              [class]="o.isDefunct == 'Y' ? 'defunct' : 'live'">
              {{ o.organisationName }}
            </option>
          </select>
        </div>
      </div>

      <p class="sidebar-meta">
        Logged in as {{ user.fullName }}<br />
        Your last logon was on {{ user.lastLogon | date }} at
        {{ user.lastLogon | date: "shortTime" }}.
      </p>
      <div class="newHome" [routerLink]="['/home']" (click)="classicView()">
      <h4 style="font-weight: bold"><i class="far fa-sparkles" style="margin-right:0.5em"></i>Try our new home page!</h4>
      <p>A new way to see your active projects, discover useful resources and events, and more...</p>
    </div>
      <div class="mobile-hr">
        <hr />
      </div>
    </div>
  </div>

  <div class="col-sm-9">
    <div *ngIf="welcomeMessage" class="alert alert-success welcome-message">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismissWelcomeMessage()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4>Welcome to the Network Members' Area.</h4>
      <p>
        You can find all the Network's projects listed on the home page. For
        your chosen Project, click on 'Project' to find tabs for Information,
        Online Toolkit, Offline Toolkit, Reports, Presentations and Case
        Studies.
      </p>
      <p>
        If you need any help, or have any comments or suggestions, please email
        <a href="mailto:e.pruce1@nhs.net">Emma Pruce</a>, or call Lucas on 0161
        266 1855.
      </p>
    </div>

    <div class="info-block">
      <a href="https://s3.eu-west-2.amazonaws.com/nhsbn-static/Not%20Project%20Specific/2025/NHSBN%20Member%20Work%20Programme%20202526.pdf" target="_blank">
        <strong><i class="far fa-file-download"></i>Click here to download the 2025/26 member work programme</strong>
      </a>
    </div>

    <app-old-project-table [organisationSelection]="currentOrganisation"></app-old-project-table>
  </div>
</div>
