<div *ngIf="sections">
  <ng-container *ngFor="let section of sections">
    <div *ngFor="let p of section.projects | filterBy: ['projectName']: searchText">
      <div class="col-md-12 project-card" *ngIf="p.activeProject == registrationStatus">
        <div class="project-list">
          <div class="col-md-10 information">
            <img alt="NHSBN {{ section.title }} logo" src="../assets/images/{{ section.title.replaceAll(' ', '') }}.png" />
            <div>
              <span [class]="section.title">{{ section.title }}</span> <br/>
              <b class="details-link" (click)="navProjectDetail(p.projectId)">{{ p.projectName }}
              <i class="far fa-info-circle" tooltip="Learn more about the {{ p.projectName }}" placement="right"></i>
              </b><br />
              <ng-container *ngIf="p.dataCollectionOpenDate !== null">
                <ng-container *ngIf="p.registrationAvailable == 'Y' && p.registrationId !== null">
                  <span (click)="navProjectRegistration(p.projectId, organisationSelection)" class="registration">
                    Registered for {{ p.year }}/{{ (p.year + 1).toString() | slice:2:4 }}
                  </span>
                  <div *ngIf="p.status && p.projectId != 36" style="display: inline;">&nbsp;&bull;</div>
                </ng-container>
                <b *ngIf="p.projectId != 36" [ngStyle]="{'color': p.statusColor}"> {{ p.status }}</b>
              </ng-container>
            </div>
          </div>
          <div class="col-md-2 project-list-buttons">
            <button class="btn btn-primary" (click)="navDashboard(p.projectId)"
              [disabled]="p.outputsAvailable === 'N' || p.allowOrganisationAccess === 'N'"
              style="margin-top: 0px;">Outputs</button><br />
            <button class="btn btn-outline-primary" *ngIf="p.signUp == 'Y'"
              (click)="navProjectRegistration(p.projectId, organisationSelection)" >Registration</button>
            <button class="btn btn-outline-primary" *ngIf="p.signUp == 'N' && p.accessProject == 'N'" disabled
              (click)="navProjectRegistration(p.projectId, organisationSelection)" >Registration</button>
            <button class="btn btn-outline-primary" *ngIf="p.accessProject == 'Y' && p.signUp == 'A'"
              (click)="navProjectOverview(p.projectId)"  >Data Collection</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="archived">
  <ng-container>
    <div *ngFor="let p of archived.projects | filterBy: ['projectName']: searchText">
      <div class="col-md-12 project-card archived-card" (click)="navProjectDetail(p.projectId)">
        <div class="section">
          <div class="col-md-10 image-title">
            <img alt="NHSBN {{ p.categoryName }} logo" src="../assets/images/{{ p.categoryName.replaceAll(' ', '') }}.png" />
            <div>
              <span [class]="p.categoryName">{{ p.categoryName }}</span> <br/>
              <b style="font-size: 1.2em">{{ p.projectName }}</b>
            </div>
          </div>
          <div class="col-md-2 button">
            <button class="btn btn-primary" (click)="navDashboard(p.projectId)"
              [disabled]="p.outputsAvailable === 'N' || p.allowOrganisationAccess === 'N'"
              style="width: 100%">Outputs</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>